import React, {
  memo, useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { createMasterIbAgreement } from "store/actions";
import {
  Row, Col, Button,
  Modal, ModalBody, ModalHeader,
} from "reactstrap";
import { FixedSizeList as List } from "react-window";
import AvFieldSelect from "components/Common/AvFieldSelect";
import validatePositiveInputs from "helpers/validatePositiveInputs";
import memoize from "memoize-one";

const InvestorForm = ({ accIdx, acc, items, changeItem }) => {
  const { t } = useTranslation();
  return <Row key={accIdx}>
    <Row className="justify-content-center mb-2 fw-bold gx-0">
      <Col md="3" className="text-center">{acc.title}</Col>
    </Row>
    <Row>
      <Col md="3" className="text-center">
        {t("PAMM Profit Share")} %
        <AvField
          name={`values[${accIdx}].accountTypeId`}
          value={acc._id}
          type="hidden"
        />
      </Col>
      <Col md="9" className="text-center">
        <AvField
          name={`values[${accIdx}].pamProfitShare`}
          type="text"
          errorMessage={t("Invalid Profit Share!")}
          value={items[accIdx]?.pamProfitShare}
          onChange={(e) => changeItem(accIdx, "pamProfitShare", e.target.value)}
          validate={{
            required: { value: true },
            min: { value: 0 }
          }}
          onKeyPress={(e) => validatePositiveInputs(e)}
        />
      </Col>
    </Row>
    <hr className="my-3" />
  </Row>;
};

const AccountForm = ({ accIdx, acc, products, markups, items, changeItem, changeItemProduct }) => {
  const { t } = useTranslation();
  return <Row key={accIdx}>
    <Row className="justify-content-end mb-2 fw-bold gx-0"> 
      <Col md="3" className="text-center">{acc.title}</Col>
      <Col className="ms-1">{t("Rebate")}</Col>
      <Col>{t("Commission")}</Col>
    </Row>
    {products.map((prod, prodIdx) =>
      <Row key={prodIdx} className="my-1 align-items-center">
        <Col md="3" className="text-center">
          {prod}
          <AvField
            name={`values[${accIdx}].accountTypeId`}
            value={acc._id}
            type="hidden"
          />
        </Col>
        <Col>
          <AvField
            name={`values[${accIdx}].products.${prod}.rebate`}
            type="string"
            value={items[accIdx]?.products?.[prod]?.rebate}
            onChange={(e) => changeItemProduct(accIdx, "rebate", e.target.value, prod)}
            errorMessage={t("Invalid value!")}
            validate={{
              required: { value: true },
              min: { value: 0 }
            }}
            onKeyPress={validatePositiveInputs}
          />
        </Col>
        <Col>
          <AvField
            name={`values[${accIdx}].products.${prod}.commission`}
            type="text"
            value={items[accIdx]?.products?.[prod]?.commission}
            onChange={(e) => changeItemProduct(accIdx, "commission", e.target.value, prod)}
            errorMessage={t("Invalid value!")}
            validate={{
              required: { value: true },
              min: { value: 0 }
            }}
            onKeyPress={validatePositiveInputs}
          />
        </Col>
      </Row>
    )}
    <Row key={accIdx} className="my-1 align-items-center">
      <Col md="3" className="text-center">
        {t("Markup")}
      </Col>
      <Col>
        <AvFieldSelect
          name={`values[${accIdx}].markup`}
          value={items?.[accIdx]?.markup}
          onChange={(e) => changeItem(accIdx, "markup", e)}
          options={(markups || []).map((obj) => {
            return ({
              label: `${obj}`,
              value: obj
            });
          })}
        />
      </Col>
    </Row>
    <hr className="my-3" />
  </Row>;
};

const MemoizedInvestorForm = memo(InvestorForm);
const MemoizedAccountForm = memo(AccountForm);

const RowItem = ({ data, index, style }) => {
  const { t } = useTranslation();
  const { allAccountTypes, markups, products, items, changeItem, changeItemProduct } = data;
  const acc = allAccountTypes[index];
  return (<div style={{
    ...style,
    width: "97%",
  }}>
    {
      acc?.type === "INVESTOR" ? 
        <MemoizedInvestorForm index={index} accIdx={index} style={style} acc={acc} t={t} items={items} changeItem={changeItem} />
        : 
        <MemoizedAccountForm index={index} accIdx={index} style={style} acc={acc} products={products} markups={markups} t={t} items={items} changeItem={changeItem} changeItemProduct={changeItemProduct} />
    }
  </div>);
};

const createItemData = memoize((allAccountTypes, products, markups, items, changeItem, changeItemProduct) => ({
  allAccountTypes,
  products,
  markups,
  items,
  changeItem,
  changeItemProduct,
}));

const PRODUCT_TPYE = () => {
  return {
    rebate: "0",
    commission: "0",
  };
};

const DATA_TYPE = (products = [], accId = "") => {
  return {
    accountTypeId: accId,
    pamProfitShare: "0",
    markup: "",
    products,
  };
};

const AddMasterIbModal = ({ show, toggle, accountTypes: allAccountTypes, products, customerId, markups }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { submitting } = useSelector((state) => state.ibAgreements);
  const [items, setItems] = useState([]);

  useEffect(() => {
    !submitting && show && toggle();
  }, [submitting]);

  useEffect(() => {
    if (allAccountTypes && products) {
      const itensData = [];
      const addedProducts = {};
      products?.forEach(prod => {
        addedProducts[prod] = PRODUCT_TPYE(prod); 
      });
      allAccountTypes?.forEach((ele) => {
        itensData?.push(DATA_TYPE(addedProducts, ele._id));
      });
      setItems(itensData);
    }
  }, [allAccountTypes, products]);
  
  function changeItem(index, key, value) {
    const itemsModified = [...items];
    itemsModified[index][key] = value;
    setItems(itemsModified);
  }
  function changeItemProduct(index, key, value, prod) {
    const itemsModified = JSON.parse(JSON.stringify(items));
    itemsModified[index]["products"][prod][key] = value;
    setItems(itemsModified);
  }
  const rowHeight = 680;
  const itemData = createItemData(allAccountTypes, products, markups, items, changeItem, changeItemProduct);

  if (!show || !allAccountTypes) return null;
  return (
    <Modal isOpen={show} toggle={toggle} centered={true} scrollable={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("New Master IB Agreement")}
      </ModalHeader>
      <ModalBody >
        <AvForm
          onValidSubmit={(e, v) => {
            dispatch(createMasterIbAgreement({
              customerId,
              title: v.title,
              // values: v.values
              values: items
            }));
          }}
        >
          <AvField
            name={"title"}
            label={t("Agreement name")}
            className="mb-3"
            type="text"
            errorMessage={t("Required!")}
            validate={{ required: { value: true } }}
          />
          <List
            height={500}
            itemCount={allAccountTypes?.length}
            itemSize={rowHeight}
            itemData={itemData}
            width="100%"
          >
            {RowItem}
          </List>
          <Button type="submit" disabled={submitting}>{t("Submit")}</Button>
        </AvForm>
      </ModalBody>
    </Modal >);
};

export default memo(AddMasterIbModal);
